import { AlertService } from '@services/alert/alert.service';
import { AuthClaims, AuthService } from '@services/auth/auth.service';
import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { ReplaySubject, map, share, switchMap, take, timer, withLatestFrom, zip } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { UserService } from '@services/user/user.service';

/**
 * Determines if a specific route can be accessed based on a given authorization claim.
 *
 * When said claim is not provided the user is nagivated back to the previous page and an error message
 * is shown.
 *
 * `requiredClaim` property is required in the data object of the route
 *
 * @example `data: {requiredClaim: 'Department:Manage'}`
 */
export const claimAuthGuard: CanActivateFn = (route, _) => {
  const alertService = inject(AlertService);
  const authService = inject(AuthService);
  const router = inject(Router);
  const translateService = inject(TranslocoService);
  const userService = inject(UserService);

  const defaultAdmin$ = userService.getAdmins().pipe(
    map(admins => admins.find(admin => admin.defaultAdmin === 1) || admins[0]),
    map(admin => `${admin.firstname} ${admin.lastname}`),
    share({ connector: () => new ReplaySubject(1), resetOnComplete: () => timer(2000) })
  );

  const modalHeader$ = translateService.selectTranslate<string>('disabled_button_modal_title');
  const modalMessage$ = defaultAdmin$.pipe(
    switchMap(adminName =>
      translateService.selectTranslate<string>('param.disabled_button_hover', { admin_name: adminName })
    )
  );

  const hasAuthClaim$ = authService.hasClaim(route.data.requiredClaim as AuthClaims);

  return zip([modalHeader$, modalMessage$]).pipe(
    take(1),
    withLatestFrom(hasAuthClaim$),
    map(([[modalHeader, modalMessage], hasClaim]) => {
      if (!hasClaim) {
        void alertService.defaultErrorMessage(modalMessage, modalHeader);
      }

      return hasClaim;
    })
  );
};
